import * as React from 'react'
import { useState, useEffect } from 'react'
import ReactMapGL, { Marker, MapRef } from 'react-map-gl'
import Geocoder from './Geocoder'
import SVG from 'react-inlinesvg'
import pinIcon from 'assets/images/icons/bluepin.svg'
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css'
import { useTranslation } from 'react-i18next'
import {
  MapClearIcon,
  StyledInput,
  SearchContainer,
  ErrorMessage,
} from 'components/Modal/NewAddressModal/AddAddress/AddAddress.styles'
import { DebouncedState } from 'use-debounce/lib/useDebouncedCallback'
import { useLazyGetAddressFromLatLngQuery } from 'services/mapbox-api'
import { AddressGeocoded } from 'types/AddressGeocoded'
import { mapMapBoxPlaceResponse } from 'common/utils/utils'
import { removeFormField } from 'slices/addressFormSlice'
import { useAppDispatch } from 'store/hooks'
import { getToken } from 'common/services/AuthInterceptor'
import { getBackendUrl } from 'common/utils/backendApp'

const PIN_SIZE = 20

export interface MapProps {
  latitude: number
  longitude: number
  countries: string
  onChange: DebouncedState<(resultAddress: AddressGeocoded) => void>
}

const Map = (props: MapProps) => {
  const { latitude, longitude, countries, onChange } = props
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const searchDefaultText = t('newAddressModal.addressTab.search')
  const applicationMapboxUrl = getBackendUrl() + '/mapbox'
  const [inputValue, setInputValue] = useState('')
  const [geocoderValue, setGeocoderValue] = useState('')
  const [placeholderValue, setPlaceholderValue] = useState(searchDefaultText)
  const [validationError, setValidationError] = useState(false)

  const [viewport, setViewport] = useState({
    width: '100%',
    height: 300,
    zoom: 16,
    latitude: latitude || 50.548187591116715,
    longitude: longitude || 7.131986305434703,
  })
  const mapRef = React.useRef<MapRef>()

  const [getAddressFromLatLng, { data: addressFromLatLngData, isSuccess: isGetAddressSuccess }] =
    useLazyGetAddressFromLatLngQuery()

  useEffect(() => {
    if (isGetAddressSuccess) {
      setViewport((v) => ({
        ...v,
        latitude: addressFromLatLngData.lat || v.latitude,
        longitude: addressFromLatLngData.lng || v.longitude,
      }))
      setValidationError(!addressFromLatLngData?.houseNumber)
      if (
        addressFromLatLngData.street &&
        (addressFromLatLngData.nation === 'DE' || addressFromLatLngData.nation === 'AT')
      ) {
        setInputValue(
          `${addressFromLatLngData.street || ''} ${addressFromLatLngData.houseNumber || ''}, ${
            addressFromLatLngData.zip || ''
          } ${addressFromLatLngData.town || ''}, ${addressFromLatLngData.nation || ''}`
        )
        setPlaceholderValue(
          `${addressFromLatLngData.street || ''} ${addressFromLatLngData.houseNumber || ''}, ${
            addressFromLatLngData.zip || ''
          } ${addressFromLatLngData.town || ''}, ${addressFromLatLngData.nation || ''}`
        )
        onChange(addressFromLatLngData)
      }
    }
  }, [addressFromLatLngData, isGetAddressSuccess, onChange])

  const onClearInput = () => {
    setInputValue('')
    setPlaceholderValue(searchDefaultText)
    dispatch(removeFormField('houseNumber'))
    setViewport({
      ...viewport,
      latitude: latitude || 50.548187591116715,
      longitude: longitude || 7.131986305434703,
    })
  }

  const transformRequest = (url: string, _: string) => {
    return {
      url: url,
      headers: { Authorization: 'Bearer ' + getToken() },
    }
  }

  return (
    <>
      <SearchContainer>
        <StyledInput error={validationError}>
          <input
            spellCheck="false"
            value={inputValue}
            defaultValue={placeholderValue}
            placeholder={placeholderValue}
            onChange={(event) => {
              setInputValue(event.target.value)
              setGeocoderValue(event.target.value)
            }}
          />
          <MapClearIcon onClick={onClearInput} />
        </StyledInput>
        {validationError && <ErrorMessage>{t('common.validation.houseNoError')}</ErrorMessage>}
      </SearchContainer>
      <ReactMapGL
        {...viewport}
        ref={mapRef}
        mapStyle="mapbox://styles/mapbox/streets-v11"
        mapboxApiUrl={applicationMapboxUrl}
        onViewportChange={(nextViewport) => setViewport(nextViewport)}
        doubleClickZoom={false}
        style={{ width: 600, height: 400 }}
        transformRequest={transformRequest}
      >
        <Geocoder
          mapRef={mapRef}
          marker={false}
          inputValue={geocoderValue}
          placeholder={placeholderValue}
          onResult={(newViewport) => {
            setPlaceholderValue(newViewport.result?.place_name)
            setInputValue(newViewport.result?.place_name)
            setGeocoderValue('')
            onChange(mapMapBoxPlaceResponse(newViewport.result))
            setValidationError(!newViewport.result?.address)
            setViewport({
              ...viewport,
              latitude: newViewport.result.geometry.coordinates[1] || viewport.latitude,
              longitude: newViewport.result.geometry.coordinates[0] || viewport.longitude,
            })
          }}
          countries={countries}
          mapboxApiAccessToken={getToken()}
          onClear={() => onClearInput()}
          origin={applicationMapboxUrl}
        />
        <Marker
          latitude={viewport.latitude || 50.548187591116715}
          longitude={viewport.longitude || 7.131986305434703}
          draggable={true}
          onDragEnd={(event) => {
            getAddressFromLatLng({ lng: event.lngLat[0], lat: event.lngLat[1] })
          }}
        >
          <SVG
            src={pinIcon}
            style={{ transform: `translate(${-PIN_SIZE / 2}px,${-PIN_SIZE}px)` }}
          />
        </Marker>
      </ReactMapGL>
    </>
  )
}

export default Map
