import axios from 'axios'
import { getBackendUrl } from 'common/utils/backendApp'
import { AuthInterceptor } from './AuthInterceptor'

const httpOptions = {
  baseURL: getBackendUrl(),
}

const http = axios.create(httpOptions)
http.interceptors.request.use(AuthInterceptor)

export default http
