export const getBackendUrl = () => {
  console.log(process.env.REACT_APP_BACKEND_LOCAL)
  switch (process.env.REACT_APP_PROFILE) {
    case 'local':
      return process.env.REACT_APP_BACKEND_LOCAL
    case 'dev':
      return process.env.REACT_APP_BACKEND_DEV
    case 'test':
      return process.env.REACT_APP_BACKEND_TEST
    case 'prod':
      return process.env.REACT_APP_BACKEND_PROD
    default:
      return ''
  }
}
